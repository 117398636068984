import React from 'react';
import Layout from '../../Components/Layout/Layout';
import './NoMatch.scss';
/**
 * @namespace NoMatch
 * @function NoMatch
 * @author Trevor Cash
 * @since 11/06/21
 * @version 1.0.0
 * @component
 */
export default function NoMatch() {
  return (
    <Layout
      className='About-Container'
      title='404'
      description='This page cannot be found'
    >
      <h1>404 Page cannot be found</h1>
    </Layout>
  );
}
